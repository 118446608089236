import React from 'react'
import './Home.css';
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card'
import possibiWhoarewe from './assets/bg.webp';
import possibiWhoarewe2 from './assets/ecommerce-3562191_1920.jpg';
import possibiWhoarewe3 from './assets/macbook-624707.jpg';
import possibiWhoarewe4 from './assets/hand-3108158_1920.jpg';
import Typist from 'react-typist';
import $ from 'jquery'; 
import Fade from 'react-reveal/Fade';

  


export const Home = () => (
  <div style={{  marginTop:"-26px",marginBottom:"-15px" , zIndex:"1"}}>
  <Container  >
<br/><br/>
<h4  className="ppp" style={{textAlign:"center" , fontWeight:"bold" , zIndex:"1" }}  > : מה אנחנו עושים</h4>

<div  className="t" >


    
   <br/>
       <Typist  >
  <span> בניית אתרים </span>
  <Typist.Backspace count={18} delay={200} />
  <span> פרסום באינטרנט </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום רשתות חברתיות </span>
  <Typist.Backspace count={28} delay={200} />
  <span> קידום גוגל </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום אורגני </span>
  <Typist.Backspace count={18} delay={200} />
  <span> שיווק דיגטלי  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> מיתוג  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> בניית אתרים </span>
  <Typist.Backspace count={18} delay={200} />
  <span> פרסום באינטרנט </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום רשתות חברתיות </span>
  <Typist.Backspace count={28} delay={200} />
  <span> קידום גוגל </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום אורגני </span>
  <Typist.Backspace count={18} delay={200} />
  <span> שיווק דיגטלי  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> מיתוג  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> בניית אתרים </span>
  <Typist.Backspace count={18} delay={200} />
  <span> פרסום באינטרנט </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום רשתות חברתיות </span>
  <Typist.Backspace count={28} delay={200} />
  <span> קידום גוגל </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום אורגני </span>
  <Typist.Backspace count={18} delay={200} />
  <span> שיווק דיגטלי  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> מיתוג  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> בניית אתרים </span>
  <Typist.Backspace count={18} delay={200} />
  <span> פרסום באינטרנט </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום רשתות חברתיות </span>
  <Typist.Backspace count={28} delay={200} />
  <span> קידום גוגל </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום אורגני </span>
  <Typist.Backspace count={18} delay={200} />
  <span> שיווק דיגטלי  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> מיתוג  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> בניית אתרים </span>
  <Typist.Backspace count={18} delay={200} />
  <span> פרסום באינטרנט </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום רשתות חברתיות </span>
  <Typist.Backspace count={28} delay={200} />
  <span> קידום גוגל </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום אורגני </span>
  <Typist.Backspace count={18} delay={200} />
  <span> שיווק דיגטלי  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> מיתוג  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> בניית אתרים </span>
  <Typist.Backspace count={18} delay={200} />
  <span> פרסום באינטרנט </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום רשתות חברתיות </span>
  <Typist.Backspace count={28} delay={200} />
  <span> קידום גוגל </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום אורגני </span>
  <Typist.Backspace count={18} delay={200} />
  <span> שיווק דיגטלי  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> מיתוג  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> בניית אתרים </span>
  <Typist.Backspace count={18} delay={200} />
  <span> פרסום באינטרנט </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום רשתות חברתיות </span>
  <Typist.Backspace count={28} delay={200} />
  <span> קידום גוגל </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום אורגני </span>
  <Typist.Backspace count={18} delay={200} />
  <span> שיווק דיגטלי  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> מיתוג  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> בניית אתרים </span>
  <Typist.Backspace count={18} delay={200} />
  <span> פרסום באינטרנט </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום רשתות חברתיות </span>
  <Typist.Backspace count={28} delay={200} />
  <span> קידום גוגל </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום אורגני </span>
  <Typist.Backspace count={18} delay={200} />
  <span> שיווק דיגטלי  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> מיתוג  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> בניית אתרים </span>
  <Typist.Backspace count={18} delay={200} />
  <span> פרסום באינטרנט </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום רשתות חברתיות </span>
  <Typist.Backspace count={28} delay={200} />
  <span> קידום גוגל </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום אורגני </span>
  <Typist.Backspace count={18} delay={200} />
  <span> שיווק דיגטלי  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> מיתוג  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> בניית אתרים </span>
  <Typist.Backspace count={18} delay={200} />
  <span> פרסום באינטרנט </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום רשתות חברתיות </span>
  <Typist.Backspace count={28} delay={200} />
  <span> קידום גוגל </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום אורגני </span>
  <Typist.Backspace count={18} delay={200} />
  <span> שיווק דיגטלי  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> מיתוג  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> בניית אתרים </span>
  <Typist.Backspace count={18} delay={200} />
  <span> פרסום באינטרנט </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום רשתות חברתיות </span>
  <Typist.Backspace count={28} delay={200} />
  <span> קידום גוגל </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום אורגני </span>
  <Typist.Backspace count={18} delay={200} />
  <span> שיווק דיגטלי  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> מיתוג  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> בניית אתרים </span>
  <Typist.Backspace count={18} delay={200} />
  <span> פרסום באינטרנט </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום רשתות חברתיות </span>
  <Typist.Backspace count={28} delay={200} />
  <span> קידום גוגל </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום אורגני </span>
  <Typist.Backspace count={18} delay={200} />
  <span> שיווק דיגטלי  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> מיתוג  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> בניית אתרים </span>
  <Typist.Backspace count={18} delay={200} />
  <span> פרסום באינטרנט </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום רשתות חברתיות </span>
  <Typist.Backspace count={28} delay={200} />
  <span> קידום גוגל </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום אורגני </span>
  <Typist.Backspace count={18} delay={200} />
  <span> שיווק דיגטלי  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> מיתוג  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> בניית אתרים </span>
  <Typist.Backspace count={18} delay={200} />
  <span> פרסום באינטרנט </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום רשתות חברתיות </span>
  <Typist.Backspace count={28} delay={200} />
  <span> קידום גוגל </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום אורגני </span>
  <Typist.Backspace count={18} delay={200} />
  <span> שיווק דיגטלי  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> מיתוג  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> בניית אתרים </span>
  <Typist.Backspace count={18} delay={200} />
  <span> פרסום באינטרנט </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום רשתות חברתיות </span>
  <Typist.Backspace count={28} delay={200} />
  <span> קידום גוגל </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום אורגני </span>
  <Typist.Backspace count={18} delay={200} />
  <span> שיווק דיגטלי  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> מיתוג  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> בניית אתרים </span>
  <Typist.Backspace count={18} delay={200} />
  <span> פרסום באינטרנט </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום רשתות חברתיות </span>
  <Typist.Backspace count={28} delay={200} />
  <span> קידום גוגל </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום אורגני </span>
  <Typist.Backspace count={18} delay={200} />
  <span> שיווק דיגטלי  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> מיתוג  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> בניית אתרים </span>
  <Typist.Backspace count={18} delay={200} />
  <span> פרסום באינטרנט </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום רשתות חברתיות </span>
  <Typist.Backspace count={28} delay={200} />
  <span> קידום גוגל </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום אורגני </span>
  <Typist.Backspace count={18} delay={200} />
  <span> שיווק דיגטלי  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> מיתוג  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> בניית אתרים </span>
  <Typist.Backspace count={18} delay={200} />
  <span> פרסום באינטרנט </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום רשתות חברתיות </span>
  <Typist.Backspace count={28} delay={200} />
  <span> קידום גוגל </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום אורגני </span>
  <Typist.Backspace count={18} delay={200} />
  <span> שיווק דיגטלי  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> מיתוג  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> בניית אתרים </span>
  <Typist.Backspace count={18} delay={200} />
  <span> פרסום באינטרנט </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום רשתות חברתיות </span>
  <Typist.Backspace count={28} delay={200} />
  <span> קידום גוגל </span>
  <Typist.Backspace count={18} delay={200} />
  <span> קידום אורגני </span>
  <Typist.Backspace count={18} delay={200} />
  <span> שיווק דיגטלי  </span>
  <Typist.Backspace count={18} delay={200} />
  <span> מיתוג  </span>
</Typist>


</div>
<br/><br/><br/>




<table className="gpt3__possibiWhoarewe section__padding" id="possibiWhoarewe">
  <tr>
    <th>
    <Fade right>
    <Card className="ca"  >
  <Card.Img variant="top" src={possibiWhoarewe} />
  <Card.Body>
    <Card.Title style={{textAlign:"center"}}> ? למה להקים אתר אינטרנט</Card.Title>
    <Card.Text style={{textAlign:"center"}}>
    רוב האנשים שצריכים שירות מקצועי או מחפשים לקנות מוצר כלשהו, רובם פונים קודם כל למנועי גוגל - חיפוש ברשת ! מי שלא נמצא בעולם הדיגטלי כאילו לא קיים וגם הלקוחות מחפשים מידע עליך. הם מחפשים כל הזמן, גם כשאינך מודע לכך. החיפוש חייב להותיר רושם חיובי. הלקוחות מחפשים חיזוק לכך שיש על מי לסמוך והיום כולם בודקים את זה באמצעות האינטרנט
    </Card.Text>
  </Card.Body>
</Card>
</Fade>
<Fade left>

      <Card className="ca">
  <Card.Img variant="top" src={possibiWhoarewe2} />
  <Card.Body>
    <Card.Title style={{textAlign:"center"}}> ? במה זה עוזר לי</Card.Title>
    <Card.Text style={{textAlign:"center"}}>
    הלקוחות מחפשים חיזוק לכך שיש על מי לסמוך, שהאדם / חברה / ארגון שעומד מנגד מייצג משהו מסודר ואמין. אתר תדמית לעסק בהחלט משרת את המטרה הזו, בעיני הלקוח המודרני הדבר מקנה תחושת רצינות וביסוס של העסק, ושלילת האפשרות שמדובר במשהו חובבני וארעי.חשבו על השחקנים הגדולים בכל תחום שבו אתם הלקוחות. האם הייתם עובדים איתם כאשר הייתם מחפשים את שם החברה ברשת ומוצאים לא יותר מעמוד פייסבוק? לכן אתר תדמית לעסק הוא חובה     </Card.Text>
  </Card.Body>
</Card>
</Fade>

    </th>

    <th>

</th>
  </tr>

</table>
<table className="gpt3__possibiWhoarewe section__padding" id="possibiWhoarewe">
  <tr>
    <th>





    </th>

    <th>
    <Fade right>

      <Card className="ca">
  <Card.Img variant="top" src={possibiWhoarewe4} />
  <Card.Body>
    <Card.Title style={{textAlign:"center"}}> ? איזה סוג אתר עדיף לי</Card.Title>
    <Card.Text style={{textAlign:"center"}}>
  קיימים כמה סוגי אתרים : אתרי תדמית - לרוב מדובר בדף נחיתה (עמוד אחד עם תבנית בסיסת ), והוא הזול ביותר, ולפעמים עוד כמה עמודים ייצוגים המציגים את העסק והשירותים שלו, בית עסק שמעוניין רק לפרסם את עצמו ושירותיו , זה סוג האתר שמתאים לו  . אתרי חנות - אלו כבר אתרים שמעונינים למכור מוצרים ולבצע משלוחים ולכן צריך באתר אבטחה גבוהה, סליקה, שרת יציב ועוד .  קיימים עוד סוגים אבל אלה העיקרים בשוק בעלי העסקים והפרטיים    </Card.Text>
  </Card.Body>
</Card>
</Fade>

<Fade left>

<Card className="ca"  >
  <Card.Img variant="top" src={possibiWhoarewe3} />
  <Card.Body>
    <Card.Title style={{textAlign:"center"}}> ? כמה זה עולה לי</Card.Title>
    <Card.Text style={{textAlign:"center"}}>
   כמה עולה לבנות אתר אינטרנט ? מחוסר ידע לגבי מחיר בניית אתר קורה לא אחת שבעלי עסקים מגיעים עם תקציב מסוים ומגלים שהם צריכים בכלל לשלם תקציב אחר לגמרי , ברוב המקרים יותר גבוה. ואז מתחיל שלב המשא ומתן המעייף על מחיר בניית אתר ובתי העסק נמצאים בנחיתות משמעותית בגלל שאינם מבינם בתחום כלל . בשביל לעלות את הסכום יכניסו כל מיני רכיבים , אלמנטורים וכו' שאולי בית העסק בכלל לא צריך . ונוסף על כך בעלי העסקים אינם מבינים שכאשר מורידים את המחיר לאתר , האתר שלכם יהיה פשוט וסביר להניח עשוי דרך כל מיני דרכים שיקצרו את התהליך של בניית האתר ומה שיקרה מכך תקבלו אתר איטי מאוד , פחות מהירות וכו


    </Card.Text>
  </Card.Body>
</Card>
</Fade>

</th>
  </tr>

</table>





    </Container>
    </div>

)


