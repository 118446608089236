import React from 'react';
///import {Alert} from "react";
import {  Navbar } from 'react-bootstrap';
import { DropdownButton,ButtonGroup,Dropdown } from 'react-bootstrap';
import styled from 'styled-components';
import b from '../img/logooo.png'
import t from '../img2/instagram.png'
import tt from '../img2/facebook.png'
import n from '../img/phone.png'
import tttt from '../img2/whatsapp.png'
import ttttt from '../img2/gmail.png'
import './Navigationdowen.css';

const closeModalButtons = document.querySelectorAll('[data-close-button]')
const overlay = document.getElementById('overlay')
const openModalButtons = document.querySelectorAll('[data-modal-target]')








const Style = styled.div`
z-index: 1;
opacity: 1.1;
position: relative;


`


const lightness = () => {
  if(true)
 
 
   document.body.style.backgroundColor = "black";
 
 
   else   alert(
     'קיימת תקלה , עמכם הסליחה',
     
     
    );
 };
 
 const FontEnlargement = () => {
 if(true)
   document.body.style.zoom="150%"
 
   else   alert(
     'קיימת תקלה , עמכם הסליחה',
     
     
    );
 
 
   };
   const small = () => {
 if(true)
     document.body.style.zoom="50%"
 
     else   alert(
       'קיימת תקלה , עמכם הסליחה',
       
       
      );
 
 
     };
 
 
 
 const colors = () => {
 if(true)
 
   document.body.style.backgroundColor = "red";
 
   else alert(
     'קיימת תקלה , עמכם הסליחה',
     
     
    );
 
 };
 
   
 const canclle = () => {
   if(true){
   document.body.style.backgroundColor = "white";
   document.body.style.zoom="100%"
   }
   else
 
   alert(
      'קיימת תקלה , עמכם הסליחה',
      
      
     );
 
 };
 

openModalButtons.forEach(button => {
  button.addEventListener('click', () => {
    const modal = document.querySelector(button.dataset.modalTarget)
    openModal(modal)
  })
})


closeModalButtons.forEach(button => {
  button.addEventListener('click', () => {
    const modal = button.closest('.modal')
    closeModal(modal)
  })
})

function openModal(modal) {
  if (modal == null) return
  modal.classList.add('active')
  overlay.classList.add('active')
}

function closeModal(modal) {
  if (modal == null) return
  modal.classList.remove('active')
  overlay.classList.remove('active')
}



////function takla(modal)    {
 /// alert(
 ///    'נגישות האתר נמצאת בטיפול , עמכם הסליחה',
     
     
 ////   );


///}


export const Navigationdowen = () => (
<Style>
  <div  style={{textAlign:"center",alignItems:"center"}}>
 <Navbar bg="dark" expand="lg" class="container-1">

<div style={{textAlign:"center",alignItems:"center"}}  className='footer-container' >


<Navbar.Brand  style={{textAlign:"center",alignItems:"center"}}  className="img"></Navbar.Brand> 
<img  src={b} alt="3" />




<div class="wrapper">

  <div class="icon facebook">
    <div  class="tooltip">פייסבוק</div>
  <span><i class="fab fa-facebook-f"></i></span>
  <a target="_blank" a href="https://www.facebook.com/hai.vaizman"> <img  src={tt} alt="3" /></a>
  </div>
  <div    class="icon twitter">
    <div class="tooltip">וואטסאפ</div>
    <span><i   class="fab fa-twitter"></i></span>
    <a target="_blank"    a href="https://wa.me/972506831839"  >  <img  src={tttt} alt="3" /></a>
  </div>
  <div class="icon instagram">
    <div class="tooltip">אינסטגרם</div>
    <span><i class="fab fa-instagram"></i></span>
    <a   target="_blank" a href="https://www.instagram.com/hai_waizman/"><img   src={t} alt="3" /></a>
    
  </div>
  <div class="icon github">
    <div class="tooltip">אימייל</div>
    <span><i class="fab fa-github"></i></span>
    <a target="_blank" a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to= haivaizman87@gmail.com">  <img  src={ttttt} alt="3" /></a>
  </div>
  <div class="icon youtube">
    <div class="tooltip"> התקשרו</div>
    <span><i class="fab fa-youtube"></i></span>
    <a  a href="tel:050-6831839" >  <img  src={n} alt="3" /></a>

  </div>

  </div>



  
 

  </div>
</Navbar>
  </div>


  </Style>
   )


   export default Navigationdowen;

   /////a href="https://www.instagram.com/hai_vaizman/"