import React, {useState} from 'react'
import './Slider.css'
import BtnSlider from './BtnSlider'
import dataSlider from './dataSlider'
import styled from 'styled-components';
import p from '../img2/img2.png';
import pp from '../img2/img4.png';
import n2 from './../../img2/img6.png'

import { Container,Table , Button } from 'react-bootstrap';





const Style = styled.div`

opacity: 1.1;
position: relative;
backgroundColor:"#DCE1E3";

`


function Moving() {


  window.open('https://jrb-hovalot.co.il/', '_blank');
  
    ;
    }
  


    function Moving2() {


        window.open('https://rishuy-asakim.co.il/', '_blank');
        
          ;
          }
        



          function Moving3() {


            window.open('https://www.jordanedri.co.il/', '_blank');
            
              ;
              }
            




export default function Slider() {

    const [slideIndex, setSlideIndex] = useState(1)

    const nextSlide = () => {
        if(slideIndex !== dataSlider.length){
            setSlideIndex(slideIndex + 1)
        } 
        else if (slideIndex === dataSlider.length){
            setSlideIndex(1)
        }
    }

    const prevSlide = () => {
        if(slideIndex !== 1){
            setSlideIndex(slideIndex - 1)
        }
        else if (slideIndex === 1){
            setSlideIndex(dataSlider.length)
        }
    }

    const moveDot = index => {
        setSlideIndex(index)
    }

    return (
        <Style style={{backgroundColor:"#DCE1E3" , marginTop:"-10px",marginBottom:"0px" }}>
  <div >
  <Container  >
<br/><br/><br/>

<div className="gpt3__possibility section__padding" id="possibility">
    <div onClick={Moving} className="gpt3__possibility-image">
      <img src={pp} alt="possibility" />
    </div>
    <div className="gpt3__possibility-content">
     
      <h1 className="gradient__text">J.R.B  <br /> חברת הובלות</h1>
      <p></p>
      <h4>כניסה לאתר לחץ על התמונה</h4>
    </div>
  </div>

<br/><br/><br/>
  <div className="gpt3__possibility section__padding" id="possibility">
    <div onClick={Moving2} className="gpt3__possibility-image">
      <img src={p} alt="possibility" />
    </div>
    <div className="gpt3__possibility-content">
    
      <h1 className="gradient__text">רישוי עסקים ישראל  <br /> חברת רישוי עסקים </h1>
      <h4>כניסה לאתר לחץ על התמונה</h4>
    </div>
  </div>
  <br/><br/><br/>
  <div className="gpt3__possibility section__padding" id="possibility">
    <div onClick={Moving3} className="gpt3__possibility-image">
      <img src={n2} alt="1" />
    </div>
    <div className="gpt3__possibility-content">
    
      <h1 className="gradient__text"> ג’ורדן אדרי הפקות <br /> תקליטן לאירועים </h1>
      <h4 style={{fontweight:"bold"}}>כניסה לאתר לחץ על התמונה</h4>
    </div>
  </div>


    </Container>
    </div>

        <div    className="container-slider" >
            {dataSlider.map((obj, index) => {
                return (





                    <div 
                    
                    key={obj.id}
                    className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
                    >
                        <img 
                        src={process.env.PUBLIC_URL + `/Imgs/img${index + 1}.jpg`} 
                        />
                    </div>
                )
            })}


            <BtnSlider moveSlide={nextSlide} direction={"next"} />
            <BtnSlider moveSlide={prevSlide} direction={"prev"}/>

            <div  className="container-dots">
                {Array.from({length: 5}).map((item, index) => (
                    <div  
                    onClick={() => moveDot(index + 1)}
                    className={slideIndex === index + 1 ? "dot active" : "dot"}
                    ></div>
                ))}
            </div>
        </div>
        </Style>

    )
}






/////<div onClick={Moving}  style={{backgroundColor:"#DCE1E3" ,marginLeft:"auto",marginRight:"auto" ,marginBottom:"auto",marginTop:"auto" }} className="flip-box"  >
 //// <Card.Img onClick={Moving}    variant="center" src={pp} />

////</div>


 ////<div className="gpt3__possibiWhoarewe section__padding" id="possibiWhoarewe"  style={{backgroundColor:"#DCE1E3" ,marginLeft:"auto",marginRight:"auto" ,marginBottom:"auto",marginTop:"auto" }} className="flip-box"   >
  ///<Card.Img    variant="center" src={p} />
///
////</div>