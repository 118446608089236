import React, {Component} from 'react';
import './Contact.css';
import Button from 'react-bootstrap/Button'
import { Container } from 'react-bootstrap';
import emailjs from "emailjs-com";
import Fade from 'react-reveal/Fade';
import LightSpeed from 'react-reveal/LightSpeed';
import n from './img/phone.png'
import tttt from './img2/whatsapp.png'
import possibiWhoarewe from './assets/1259.png';
import styled from 'styled-components';
import ReCAPTCHA from "react-google-recaptcha";

const Style = styled.div`

position: relative;

`




///const btnScrollToTop = document.querySelector("#btnScrollToTop");

export default class Contact extends Component { 
  







    constructor(props) {
        super(props);

        

        this.state =
            {
               name:'',
               contactEmail:'',
               contactMessage:''

                
            };

            this.handleonChange= this.handleonChange.bind(this);


          }


          handleonChange(value) {
            console.log("Captcha value:", value);
          }

          sendEmail(e) {
            e.preventDefault();
    
            emailjs.sendForm('service_wnyoqh7', 'template_fixlo15', e.target, 'user_7XjWzaNdKiGR2BG0wBiLt')
                        .then((result) => {
                console.log(result.text);
                alert('הודעה נשלחה בהצלחה , ניצור קשר בהקדם! תודה ')
            }, (error) => {
                console.log(error.text);
                alert('הודעה לא נשלחה , נסה שנית')
            });
            e.target.reset()
        }


  emailChek=(event)=>   {
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const email =event.target.value;
    if(regex.test(email)){
     event.target.style= "background-color: rgba(141, 194, 111, 150)";
     
     }
    else{
        event.target.style= "background-color: rgba(214, 94, 70, 150)";
        

    }
    this.UserLogin  = event.target.value;
  
  }
  



   
    render() {
        
        return (
            
         <Style>


  <div style={{backgroundColor:"#DCE1E3" , marginTop:"-10px",marginBottom:"-15px" }} onSubmit={this.handleSubmit} className="gpt3__possibiWhoarewe section__padding" id="possibiWhoarewe">
     

  <form style={{marginLeft:"auto",marginRight:"auto" , justifyContent:"center",alignItems:"center",marginTop:"auto",marginBottom:"auto",textAlign:"center" , zIndex:"1" }}  id="contact-form" method="POST">
  <br></br>
  <img src={possibiWhoarewe} alt="possibiWhoarewe" />
  <br/>
  <div class="wrapper">


<div  class="icon facebook">
  <div  class="tooltip">התקשרו</div>
  <span ><i   class="fab fa-twitter"></i></span>
  <a   a href="tel:050-6831839" >  <img  src={n} alt="3" /></a>
</div>


<div  class="icon twitter">
  <div  class="tooltip">הודעה</div>
  <span ><i  class="fab fa-youtube"></i></span>
  <a target="_blank"    a href="https://wa.me/972506831839"  >  <img  src={tttt} alt="3" /></a>


</div>

</div> 
  <br />


   <Container >
   <LightSpeed right>
   <head>           <script src="https://www.google.com/recaptcha/api.js" async defer></script>
           </head>
   <form style={{zIndex:"1"  }}  onSubmit={this.sendEmail}>

                    <div style={{zIndex:"1" }}   className="row pt-5 mx-auto">
                        <div style={{zIndex:"1" }}  className="col-8 form-group pt-2 mx-auto">
                     
                   
                        <h4  className="ppp" style={{textAlign:"center" , fontWeight:"bold" , zIndex:"1" }}  >:השאירו פרטים ונחזור אליכם</h4>
                        <br />
                   
                            <input  style={{textAlign:"right" , zIndex:"1" ,borderRadius:"150px", border: "1px solid green"  }} type="text" className="form-control" placeholder="שם" name="name" required />
                        </div>
                        <div style={{zIndex:"1" }} className="col-8 form-group pt-2 mx-auto">
                            <input style={{textAlign:"right", zIndex:"1",borderRadius:"150px", border: "1px solid green"  }} type="email" className="form-control" placeholder="איימל" name="email" required />
                        </div>
                        <div style={{zIndex:"1" }} className="col-8 form-group pt-2 mx-auto">
                            <input style={{textAlign:"right", zIndex:"1" ,borderRadius:"150px", border: "1px solid green"  }} type="phone" className="form-control" placeholder="טלפון" name="phone"  />
                        </div>
                        <div style={{zIndex:"1" }} className="col-8 form-group pt-2 mx-auto">
                            <textarea style={{textAlign:"right", zIndex:"1" ,borderRadius:"20px" , border: "1px solid green" }} className="form-control" id="" cols="30" rows="8" placeholder="גוף ההודעה" name="message" required ></textarea>
                        </div>
                        <div style={{zIndex:"1",textAlign:'center' }} className="col-8 pt-3 mx-auto" >
                          <ReCAPTCHA required 
                             sitekey="6LdxKdUdAAAAAGOyVnl1Fs8_GimLD8I8ELh4KDhW "

                                onChange={this.handleonChange}
                                          />,   
                                          </div>                            
                        <div style={{zIndex:"1" }} className="col-8 pt-3 mx-auto" style={{textAlign:'center'}}>
                            <input onChange={this.handleonChange} style={{textAlign:"center", zIndex:"1"   }} type ="submit" className="btn btn-info"   value="שלח"></input>
  

                        </div>
                     
                    </div>
                 
                </form>
<br/>



                </LightSpeed>
            </Container>
         
   </form>

 <br></br><br></br>


   </div> 

                

   </Style>
            
            
        );

    }
}
























////backgroundColor:"#252f5d"


////React.render(<Contact/>, document.getElementById('app'));






////<Button   onClick={ () => { this.scrollStep();}}  className="btnbtn" variant="primary"> למעלה</Button>

///<Button  a href = 'mailto:shani.aviv.arch@gmail.com?body="Yourbody"&subject="a subject".com' className="btnbtn" variant="primary">שליחה למייל</Button>


////<Button  a href="https://wa.me/972551234567" className="btnbtn" >  <img className="im"  src={ccc} alt="3"  a href="https://wa.me/972551234567"  />&nbsp;&nbsp;שליחת הודעה</Button>




///<img className="mobile" src={c} alt="3"/> <img className="mobile" src={cc} alt="3"/>