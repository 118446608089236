import React from 'react';
import { Link } from 'react-router-dom';
import './NavigationBar.css';
import { useDropdownToggle } from "react-overlays";
import styled from "styled-components";
import ima from '../img/logooo.png'
import n from '../img/phone.png'
import { Nav, Navbar,Button } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';









setTimeout(() => {debugger;}, 5000)





const Styles = styled.div`





////showe all screen auto
position: fixed;
position: sticky;

width: 100%;
height: 100%;
left: 0;
top: 0;
overflow: hidden;
z-index: 999;






.navbar {
  opacity: 0.9;
  // or any value higher than your other elements
}

.img{
 
  height: 42px;
  width: 42px;
  z-index: 1;
  text-align: right;
    
   
}

}
.text{
  font-size: 18px;
  font-style: normal;
  text-align: right;
  font-weight: bold;
  color: '#000000';


  opacity: 1.5;

}

.te{
  color: '#bbb';
  font-size: 15px;
  font-weight: bold;
  background-color:  #0693e3  ;
  font-family:david;
  border-radius: 20px;
  text-align: right;
  margin: 0 auto;

}
.zoom:hover {
 
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  transition: all 0.7s ease-in-out;
}
.tex{
  font-size: 18px;
  font-weight: bold;
  color: '#FFFFFF';
  text-align: right;
  
 

}

a, .navbar-brand, .navbar-nav .nav-link {
  z-index: 2;
  
  
  &:hover {
    color:'#FFFFFF';
   
  }

}
`


///function takla(modal)    {
 /// alert(
  ///   'נגישות האתר נמצאת בטיפול , עמכם הסליחה',
     
     
   /// );


///}



function scrollStep() {
  window.scrollBy( { 

    top: 600,
    behavior: 'smooth'

});
}

export const NavigationBar = () =>   (
  
 <Styles  >






<Navbar   expand="lg" class="container-1">
<Fade bottom>

   <Button style={{ fontSize:"13px" , fontWeight:"bold",backgroundColor:"#0693e3" ,borderRadius:"20px",textAlign:"right",zIndex:"999"  }} href="tel:050-6831839"  className="te" className="zoom"   variant="light"><img  src={n} alt="1"/>&nbsp;לייעוץ חינם ! צור קשר</Button>


  </Fade>
    
    <Navbar.Collapse  id="basic-navbar-nav">

    <Nav  className="ml-auto" >
    <Fade bottom>
    <div  className="box6"  > 
 

 <Nav.Item  >
   <Nav.Link >

   <img   src={ima} alt="1"/> 

   </Nav.Link>
 </Nav.Item>
 </div>
      
           <div  className="box1"  > 
 

          <Nav.Item  >
         
            <Nav.Link >
           
            <Link style={{ fontSize:"18px"    }}   onClick= {scrollStep}  className="tex"  to="/"> בית </Link>

            </Nav.Link>
           
          </Nav.Item>
          </div>
          <div className="box2">
          <Nav.Item >
            <Nav.Link  >
            <Link style={{ fontSize:"18px"   }} onClick= {scrollStep}  className="tex"   to="/About">אודות</Link>

            </Nav.Link>
     
          
          </Nav.Item>
          </div>
          <div className="box3">
          <Nav.Item >
            <Nav.Link  >
            <Link style={{ fontSize:"18px"   }} onClick= {scrollStep}  className="tex"   to="/Slider">תיק עבודות בניית אתרים</Link>

            
            </Nav.Link>
     
          
          </Nav.Item>
          </div>
          <div className="box4">
          <Nav.Item  >
          <Nav.Link class="box4">
            <Link style={{ fontSize:"18px"   }} onClick= {scrollStep} className="tex"  to="/contact">יצירת קשר</Link>
            
            </Nav.Link>
          </Nav.Item>
          </div>
          <div className="box5">
          <Nav.Item >
          <Nav.Link class="box5">
            <Link style={{ fontSize:"18px"   }} onClick= {scrollStep} className="tex"  to="/Websiteterms">תקנון האתר</Link>
            
            </Nav.Link>
          </Nav.Item>
          </div>
          <Nav.Item >
          <Nav.Link>

   
            
            </Nav.Link>
          </Nav.Item>
          </Fade >
        </Nav>
        </Navbar.Collapse>
    
   </Navbar>
 








       
    
   


  



</Styles>

)

////<Navbar bg="dark" variant="dark">

///////////  <Navbar.Toggle style={{position: "fixed" , marginRight:"auto" , textAlign:"center"}}   class="navbar-toggler navbar-toggler-right"  aria-controls="basic-navbar-nav"  />
///<Nav  bg="primary" variant="dark" className="imgg" >

///</Nav>

  // <Navbar  bg="dark" expand="lg">

///<Nav  className="ml-auto">

///<Fade bottom>

///<Navbar.Brand  style={{fontWeight:"bold" , color:"white" }} > הובלות J.R.B &nbsp;&nbsp;<img className="aaa" src={ima} alt="1"/>  </Navbar.Brand>  
///</Fade>




///</Nav>

///</Navbar>
