import React from 'react';
import { Button } from 'react-bootstrap';
import './Screen.css';
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';
import styled from 'styled-components';
import Typist from 'react-typist';
import a from './img2/down-chevron.png'


const Style2 = styled.div`




z-index : 9;



`



function scrollStep() {
  window.scrollBy( { 

    top: 500,
    behavior: 'smooth'

});
}






function Screen() {
  return (
    <Style2>


    <div   className="overlay">



   
<Zoom bottom>
<div  className="heder"    >

  <div style={{backgroundColor:"white" , opacity:"0.8",borderRadius:"50px" , textAlign:"center" ,marginLeft:"auto",marginRight:"auto",marginTop:"auto",marginBottom:"auto"}} className="hedertex" >
  <Typist>
<h1  className="hedertex"  style={{textAlign:"center", color:"red" ,}} >הקמת אתרי אינטרנט</h1>
<h1 className="hedertex"  style={{textAlign:"center", color:"red"}}>לבעלי עסקים ופרטיים </h1>
  <h2 className="hedertex"   style={{textAlign:"center",alignItems:"center" ,fontFamily:"david" }}>אתר אינטרנט תדמיתי לעסק, גם הקטן ביותר – הוא </h2>
  <h2 className="hedertex"   style={{textAlign:"center",alignItems:"center" ,fontFamily:"david" }}>  חובה במציאות של היום, להקים אתר תדמית פשוט</h2>
  <h2 className="hedertex"   style={{textAlign:"center",alignItems:"center",fontFamily:"david" }}>לא מוכרח להיות סיפור יקר, הוא מספק כל כך הרבה</h2>
  <h2 className="hedertex"   style={{textAlign:"center",alignItems:"center",fontFamily:"david" }}> תועלות לביסוס מעמדו של העסק ומיתוגו, כל המידע</h2>
  <h2 className="hedertex"   style={{textAlign:"center",alignItems:"center",fontFamily:"david" }}>! קיים באתר. עסק שלא מבין את זה – יבין בעתיד</h2>

  </Typist>
  <h2 className="hedertex"   style={{textAlign:"center",alignItems:"center",fontFamily:"david" }}><img onClick={scrollStep}  src={a} alt="3" /></h2>
  </div>


        </div>

        </Zoom>



    

 
    </div>


    </Style2>

          
 

  )
}

export default Screen;





