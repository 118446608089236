import React from 'react'
import './Websiteterms.css';
import { Container } from 'react-bootstrap';
import Roll from 'react-reveal/Roll';
import possibiw from './assets/W.png';


















export const Websiteterms = () => (
<div style={{backgroundColor:"#DCE1E3" , marginTop:"-10px",marginBottom:"-15px"}}>

<Container>




<br></br>

 
      
    <div   className="bb">
<Roll top>
<h4 style={{textAlign:"center" , fontWeight:"bold" }}  ><img src={possibiw} alt="possibility" /></h4>

<p>.הינו אתר אינטרנט הכולל מידע ושירותים שונים בענף האינטרנט והעולם הדיגטלי   “	haimsite.co.il	” ברוכים הבאים לאתר </p>
<p> ,תקנון אתר זה, המהווה גם לתנאי השימוש באתר נכתבים בלשון זכר יחיד מטעמי נוחות השפה </p>
<p>.אך מתייחסים לשני המינים, לרבים, וכן לחברות, גופים ותאגידים משפטיים ו/או עסקיים בצורה שווה </p>
<p> אנא קראו תקנון זה ואת תנאי השימוש בו בעיון רב, בטרם גלישתך באתר , שכן המשך גלישה וצפייה</p>
<p>  במידע הנמצא באתר ו/או בתכניו מהווים הסכמתך לתקנון האתר (מסמך זה, להלן: "תקנון אתר") במלואו</p>
<p>.השימוש באתר ובתכנים שבו ניתנים לשימוש כפי שהם וללא כל התחייבות או אחריות מכל סוג שהוא </p>
<p>  השימוש בתכנים המוצגים באתר כפי שמצוינים בהגדרת המונח "האתר", וכן בשירותי האתר ייעשו על אחריותו </p>
<p> . הבלעדית והמלאה של המשתמש</p>

 <p></p>
 <p>.אתר זה הינו לשימושו האישי בלבד של המשתמש והוא באחריותו בלבד  </p>


 
 </Roll>
</div>
  

    



  
</Container>



</div>
   
 
  
)
