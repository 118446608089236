
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home } from './Home';
import  About  from './About';
import  Contact  from './Contact';
import { Websiteterms } from './Websiteterms';
import { Layout } from './components/Layout';
import { NavigationBar } from './components/NavigationBar';
import Navigationdowen  from './components/Navigationdowen';
import Screen from './components/Screen'; 
import HamburgerMenu from './components/Nav/HamburgerMenu';
import Slider from './components/Slider/Slider';
import styled from "styled-components";
import Background from "./assets/macbook-624707.jpg";
import { AccessibilityWidget } from 'react-accessibility';
import FloatingWhatsApp from 'react-floating-whatsapp'


const Styleapp = styled.div`






`;




class App extends Component {
  render() {

    return (
   

      <React.Fragment>
        <Router>
  
        < NavigationBar /> 
        <HamburgerMenu/>
        <Styleapp className='App' >
        
          <Screen />
     
          <FloatingWhatsApp />
            
    
          <AccessibilityWidget />
     
       

          </Styleapp>
          <Switch>
              <Route exact path="/" component={Home} /> 
              <Route path="/Slider" component={Slider } />       
              <Route path="/About" component={About} />
              <Route path="/contact" component={Contact} />
              <Route path="/Websiteterms" component={Websiteterms} />
           
           

             
              </Switch>
          
          
         

         
          
        </Router>

        <Navigationdowen/>

      </React.Fragment>
      
    


    );
  }
}

export default App;





///display: block;
///width: auto;
///height: auto;
///background:url(${Background}); 
///background-repeat: no-repeat;
///background-attachment: fixed;
///background-position: center;
///background-size: cover;
///z-index : -100000;

///@media only Screen and (max-width: 40em) {
 /// width: auto;
 // height: auto;
 /// background-position: top center;
 /// background-repeat:no-repeat;
 /// background-size: cover;
 /// z-index: -1;
 // opacity: 1.0;
 /// .slick-slider .slick-arrow {
 ///   display: none;
  //  width: auto;
 /// }
